import Vue from "vue";
import { mdiCancel, mdiCashPlus, mdiCurrencyUsd, mdiCash } from "@mdi/js";
import { TipoDenominacion, DetalleOrdenDotacion } from "apd.sistemapagos.models";

export default Vue.extend({
  name: "DetalleDotacion",
  props: {
    detalle: {
      type: Array,
      required: true,
    },
    editar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      Icons: {
        Remover: mdiCancel,
        Cantidad: mdiCashPlus,
        Denominacion: mdiCurrencyUsd,
        TipoDenominacion: mdiCash,
      } as Record<string, string>,
      headers: [
        {
          text: "Cantidad",
          align: "end",
          sortable: false,
          value: "cantidad",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "x",
        },
        {
          text: "Denominación",
          align: "end",
          sortable: false,
          value: "denominacion",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "=",
        },
        {
          text: "Total",
          align: "end",
          sortable: false,
          value: "total",
        },
      ],
      billete: 0,
      moneda: 1,
      denominaciones: [
        { valor: 100, tipo: 0 },
        { valor: 50, tipo: 0 },
        { valor: 10, tipo: 1 },
        { valor: 5, tipo: 1 },
        { valor: 1, tipo: 1 },
      ],
    };
  },
  computed: {
    detalleArray(): DetalleOrdenDotacion[] {
      return this.detalle as DetalleOrdenDotacion[];
    },
    total(): number {
      const detalleOD = this.detalleArray as DetalleOrdenDotacion[];
      return detalleOD.reduce((r, i) => r + Number(i.cantidad * i.denominacion), 0);
    },
    tipoDenominacionToString() {
      return (value: number): string => {
        return TipoDenominacion[value];
      };
    },
  },
  methods: {
    changeDenominacion(index: DetalleOrdenDotacion) {
      const denominacion = this.denominaciones.find((d) => d.valor == index.denominacion);
      index.tipoDenominacion = denominacion?.tipo as TipoDenominacion;
    },
    remove(index: number) {
      this.detalleArray.splice(index, 1);
    },
  },
});
