import { render, staticRenderFns } from "./detalleDotacion.vue?vue&type=template&id=3e942efc&class=ml-0%20pl-0%20mr-0%20pr-0"
import script from "./detalleDotacion.ts?vue&type=script&lang=ts&external"
export * from "./detalleDotacion.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports